/**
 * Internal dependencies
 */
import Accordion from "../../js/components/accordion";

document.addEventListener("DOMContentLoaded", function () {
	const accordionElements = document.getElementsByClassName(
		"js-bsab4s-accordion"
	);
	if (accordionElements.length > 0) {
		for (const accordionElement of accordionElements) {
			new Accordion(accordionElement);
		}
	}
});
